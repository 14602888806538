import PropTypes from "prop-types";
import cn from "classnames";

export function PageHeading({ className = "", children }) {
  return (
    <h2>
      {children}
    </h2>
  );
}

PageHeading.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf([PropTypes.string, PropTypes.shape({})]),
  ]).isRequired,
  className: PropTypes.string,
};
