/**
 * To render a different wrapping element based on a condition,
 * we can use this one line functional component and determine
 * in our component when(condition) and how(wrapper) should the children be wrapped.
 *
 * @example
 * <ConditionalWrapper
 *  condition={link}
 *  wrapper={children => <a href={link}>{children}</a>}
 * >
 *    <h2></h2>
 * </ConditionalWrapper>
 *
 */

export const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;
