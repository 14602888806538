export function makeLpTitle(title) {
  const siteName = "������ϲʼ�ʱ����";

  /* In some cases, editors have already appended the title tag, skip those */
  if (title?.includes("- ������ϲʼ�ʱ����")) {
    return title;
  }

  return title ? `$ - ${siteName}` : siteName;
}
