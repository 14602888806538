import PropTypes from "prop-types";

import { Pageblock } from "@components/pageblock";
import { Ad } from "@components/ad";
import { LatestArticles } from "@components/latestArticles";
import { SectionHeading } from "@components/headings/section";
import { Carousel } from "@components/carousel";
import { BasicCard } from "@components/cards/basic";
import { Eyebrow } from "@components/eyebrow";
import { Button } from "@components/button";
import { SocialMedia } from "@components/socialMedia";

import { GuideBooks } from "@components/_pages/homepage/guidebooks";
import { Hero3up } from "@components/hero3up";
import { BITPromo } from "@components/_pages/best-in-travel/bitPromo";
import { ElsewhereDriver } from "@components/elsewhereDriver/elsewhereDriver";

import { tagular } from "@cohesion/tagular";
import { keyify } from "@utils/keyify";
import { dangerHTML } from "@utils/danger";

import homepageAPI from "@queries/homepage/api";
import { makeLpTitle } from "@utils/makeLpTitle";
import { LP_URL } from "@constants/lpUrl";
import { NextHead } from "@components/nextHead";
import { NewsletterWidget } from "@components/newsletter/newsletterWidget";

export default function Homepage({ data }) {
  const { title, metaTags, hero, latestStories, travelPicks, instagrams } =
    data;

  const trendingDestinationName = travelPicks.destinations.find(
    place =>
      place.badges && place.badges.some(badge => badge.slug === "trending")
  )?.name;

  const [primary, ...additional] = latestStories || [];

  return (
    <div>
      <NextHead metadata={metaTags}>
        <title>{makeLpTitle(title)}</title>
        <link key="canonical" rel="canonical" href={LP_URL} />
        <meta key="og:url" property="og:url" content={LP_URL} />

        <script
          type="application/ld+json"
          {...dangerHTML({
            __html: JSON.stringify({
              "@context": "http://www.schema.org",
              "@type": "Organization",
              name: "������ϲʼ�ʱ����",
              url: LP_URL,
              sameAs: [
                "https://www.instagram.com/lonelyplanet/",
                "https://www.facebook.com/lonelyplanet",
                "https://www.twitter.com/lonelyplanet",
                "https://www.tiktok.com/@lonelyplanet",
                "https://www.youtube.com/lonelyplanet",
                "https://www.pinterest.com/lonelyplanet/",
                "https://www.linkedin.com/company/lonely-planet",
              ],
              logo: "https://lonelyplanetstatic.imgix.net/marketing/2022/lp_logo_google.png",
            }),
          })}
        />

        <script
          type="application/ld+json"
          {...dangerHTML({
            __html: JSON.stringify({
              "@context": "http://schema.org",
              "@type": "WebSite",
              url: LP_URL,
              potentialAction: {
                "@type": "SearchAction",
                target: `${LP_URL}/search?places[query]={search_term_string}`,
                "query-input": "required name=search_term_string",
              },
            }),
          })}
        />
      </NextHead>

      <Hero3up data={hero} />

      <Pageblock>
        <div className="container">
          <Eyebrow>Plan your trip</Eyebrow>
          <div className="flex justify-between items-center">
            <SectionHeading className="mb-8 lg:mb-16">
              {travelPicks.sectionTitle}
            </SectionHeading>

            <Button
              href="/places"
              className="hidden lg:inline-block mb-4 flex-shrink-0"
              onClick={() =>
                tagular("click", {
                  actionOutcome: "INTERNALLINK",
                  outboundUrl: "/places",
                  webElement: {
                    location: "SECTION",
                    elementType: "LINK",
                    position: "TRAVELPICKS",
                    text: "VIEWALLDESTINATIONS",
                  },
                })
              }
            >
              View all destinations
            </Button>
          </div>
        </div>

        <Carousel className="mb-8">
          {travelPicks.destinations.map(({ name, image, slug }, id) => (
            <BasicCard
              key={keyify(name)}
              type="destination"
              href={slug}
              image={{ url: image }}
              title={name}
              taxonomy={
                name === trendingDestinationName
                  ? "🔥 Trending destination"
                  : null
              }
              onClick={() =>
                tagular("click", {
                  actionOutcome: "INTERNALLINK",
                  outboundUrl: `/${slug}`,
                  webElement: {
                    location: "CARDS",
                    elementType: "LINK",
                    position: "TRAVELPICKS",
                    text: `CARD${id + 1}`,
                  },
                })
              }
            />
          ))}
        </Carousel>

        <Button
          href="/places"
          className="block lg:hidden w-content mx-auto"
          onClick={() =>
            tagular("click", {
              actionOutcome: "ANCHOR",
              outboundUrl: "/places",
              webElement: {
                location: "SECTION",
                elementType: "LINK",
                position: "TRAVELPICKS",
                text: "VIEWALLDESTINATIONS",
              },
            })
          }
        >
          View all destinations
        </Button>
      </Pageblock>

      <Pageblock>
        <Ad placement="fullWidth" path="homepage/top" />
      </Pageblock>

      <BITPromo
        hero={{
          poster:
            "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2024/campaign/hero-bit-2024.png?auto=format&w=1920&h=1080&fit=crop&q=40",
          url: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2024/video/campaign.mp4",
        }}
      />

      <ElsewhereDriver />

      <Pageblock>
        <LatestArticles
          eyebrow="Travel stories and news"
          title="Explore our latest stories"
          articles={{
            primary,
            additional,
          }}
          enableFilters={false}
          showNewsLink
        />
      </Pageblock>

      <NewsletterWidget
        stackedLayout
        topographyBackground
        formId="Homepage-banner"
      />

      <Pageblock flush>
        <GuideBooks />
      </Pageblock>

      <Pageblock flush inset className="bg-blue">
        <SocialMedia promos={instagrams} />
      </Pageblock>
    </div>
  );
}

export async function getServerSideProps({ req, res }) {
  res.setHeader("Cache-Control", "no-cache, max-age=120, must-revalidate");
  const data = await homepageAPI(req, res);
  return {
    props: {
      data,
    },
  };
}

Homepage.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    metaTags: PropTypes.arrayOf(PropTypes.shape({})),
    hero: Hero3up.propTypes.data,
    travelPicks: PropTypes.shape({
      destinations: PropTypes.arrayOf(PropTypes.shape({})),
      sectionTitle: PropTypes.string,
    }),
    latestStories: PropTypes.arrayOf(PropTypes.shape({})),
    instagrams: PropTypes.arrayOf(
      PropTypes.shape({
        userName: PropTypes.string,
        profileUrl: PropTypes.string,
        image: PropTypes.shape({
          url: PropTypes.string,
          alt: PropTypes.string,
        }),
      })
    ).isRequired,
  }).isRequired,
};
